.root {
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-size: 14px;
  font-weight: 700;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--colorGrey200);
  transition: 0.4s;
  border-radius: 20px;

  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    border-radius: 50%;
    left: 3px;
    bottom: 3px;
    background-color: var(--colorWhite);
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: var(--spxGreen);
}

input:checked + .slider:before {
  transform: translateX(14px);
}
